export enum RepoMap {
	ResourceRepository = 'resource',
	AssignmentRepository = 'assignment',
	QuizRepository = 'quiz',
	ExternalLinkRepository = 'external',
	LectureRepository = 'lecture',
	ForumRepository = 'forum',
	FeedbackRepository = 'feedback',
}

export enum RepoIconMap {
	ResourceRepository = '/img/activities/Group 1.svg',
	AssignmentRepository = '/img/activities/Group 8.svg',
	QuizRepository = '/img/activities/Group 11.svg',
	ExternalLinkRepository = '/img/activities/Group 6.svg',
	LectureRepository = '/img/activities/Group 15.svg',
	ForumRepository = '/img/activities/Group 13.svg',
	FeedbackRepository = '/img/activities/Group 13.svg',
}
