import { getGrades } from 'API/course'
import { PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React from 'react'

import { RepoMap } from 'components/RepoMap'

const GradesView: React.FC<PageProps> = ({ params: { id } }) => {
	const ref = React.useRef<HTMLTableElement>(null)
	const { ShowDetails, viewAs, course } = useGetCourseDetails(id)
	const [offset, setOffset] = React.useState(0)
	const [loading, setLoading] = React.useState(false)
	const [grades, setGrades] = React.useState<{ name: string; __t: string; marks?: number; total?: number }[]>()
	const [gradesAll, setGradesAll] = React.useState<
		{
			username: string
			first_name: string
			last_name: string
			submissions: { __t: string; submission: number; marks: number; name: string }[]
		}[]
	>()

	React.useEffect(() => {
		const run = async () => {
			// if (gradesAll) {limit
			const limit = 5
			const grades = await getGrades(id, viewAs === 'Teacher', limit, offset)
			console.log(grades?.[0])
			if (viewAs === 'Teacher' && grades) {
				setOffset(offset + limit)
				setGradesAll(grades)
			} else {
				setGrades(grades)
			}
			// }
		}
		run()
	}, [viewAs])

	React.useEffect(() => {
		if (ref.current) {
			Array.from(ref.current.children).map((elem) => {
				console.log(elem)
			})
		}
	}, [ref])
	return (
		<>
			{ShowDetails}
			{viewAs === 'Student' && grades && (
				<>
					<div className='p2'>
						<h2 className='py-2 '>Marks</h2>
						{StudentGrades(grades)}
					</div>
				</>
			)}

			{viewAs === 'Teacher' && (
				<>
					{(gradesAll?.length ?? 0) > 0 ? (
						<>
							<div
								onClick={() => {
									const data: string[][] = []
									if (ref.current) {
										Array.from(ref.current.children).map((elem) => {
											Array.from(elem.children).map((elem) => {
												const base: string[] = []
												Array.from(elem.children).map((elem) => {
													base.push(elem.innerHTML)
												})
												data.push(base)
											})
										})
									}
									const text = data
										.map((elem) => {
											return elem.join()
										})
										.join('\n')
									if (window !== undefined) {
										const element = document.createElement('a')
										element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
										element.setAttribute('download', `grades.${course?.course.uid}.csv`)

										element.style.display = 'none'
										document.body.appendChild(element)

										element.click()

										document.body.removeChild(element)
									}
								}}
								className='btn btn-primary'
							>
								Download
							</div>
							<div
								onClick={async () => {
									if (gradesAll) {
										const limit = 5
										setOffset(offset + limit)
										setLoading(true)
										const grades = await getGrades(id, viewAs === 'Teacher', limit, offset)
										console.log(grades)
										if (viewAs === 'Teacher' && grades) {
											gradesAll.forEach((elem, index) => {
												elem.submissions.push(...grades[index].submissions)
											})
											setLoading(false)
										} else {
											setGrades(grades)
										}
									}
								}}
								className='btn btn-primary'
							>
								{loading ? 'Loading...' : 'Load More'}
							</div>
							<table ref={ref} className='table'>
								<thead>
									<tr>
										<th scope='col'>Name</th>
										<th scope='col'>Username</th>
										{gradesAll?.[0].submissions.map((elem) => (
											<>{<th>{elem.name}</th>}</>
										))}
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope='col'>#</th>
										<th scope='col'>Type</th>
										{gradesAll?.[0].submissions.map((elem) => (
											<>{<th>{(RepoMap as any)[elem.__t]?.toUpperCase()}</th>}</>
										))}
									</tr>
									<tr>
										<th scope='col'>#</th>
										<th scope='col'>Total</th>
										{gradesAll?.[0].submissions.map((elem) => (
											<>{<th>{elem.marks ?? 'Graded'}</th>}</>
										))}
									</tr>
									{gradesAll?.map((elem, index) => (
										<tr key={elem.first_name + index}>
											<td scope='col'>{`${elem.first_name} ${elem.last_name}`}</td>
											<td scope='col'>{`${elem.username}`}</td>
											{elem.submissions.map((elem) => (
												<>{<td>{elem.submission}</td>}</>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</>
					) : (
						'Fetching'
					)}
				</>
			)}
		</>
	)
}
export default GradesView

function StudentGrades(grades: { name: string; __t: string; marks?: number | undefined; total?: number | undefined }[]) {
	return (
		<table className='table'>
			<thead>
				<tr>
					<th scope='col'>#</th>
					<th scope='col'>First</th>
					<th scope='col'>Last</th>
					<th scope='col'>Marks</th>
					<th scope='col'>Total</th>
				</tr>
			</thead>
			<tbody>
				{grades.map((elem, index) => (
					<>
						<tr key={index}>
							<th>{index + 1}</th>
							<td>{elem.name}</td>
							<td>{(RepoMap as any)[elem.__t]?.toUpperCase()}</td>
							<td>{elem.marks}</td>
							<td>{elem?.total}</td>
						</tr>
					</>
				))}
			</tbody>
		</table>
	)
}
